import React, { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { Box, Button, Modal, Typography } from '@mui/material';
import Navbar from './Navbar.js';

const postsData = [
  { id: 1, type: "post", etat: "Bien", codeRef: "P001" },
  { id: 2, type: "post", etat: "Detruire", codeRef: "P002" },
  { id: 3, type: "post", etat: "Bien", codeRef: "P003" },
];

const piegesData = [
  { id: 1, type: "piege", etat: "Bien", codeRef: "T001" },
  { id: 2, type: "piege", etat: "Detruire", codeRef: "T002" },
  { id: 3, type: "piege", etat: "Bien", codeRef: "T003" },
];

const Plan = () => {
  const containerRef = useRef(null);
  const [rectCount, setRectCount] = useState(4);
  const layerRef = useRef(null);
  const trRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [initialImageState, setInitialImageState] = useState({
    src: null,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    rotation: 0
  });
  const [CodeRef, setCodeRef] = useState("");
  const [Type, setType] = useState("");
  const [Etat, setEtat] = useState("");
  const [selectedShape, setselectedShape] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openP, setOpenP] = useState(false);
  const handleOpenP = () => setOpenP(true);
  const handleCloseP = () => setOpenP(false);

  useEffect(() => {
    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;
    
    const stage = new Konva.Stage({
      container: containerRef.current,
      width: containerWidth,
      height: containerHeight,
      draggable: true,
    });

    const layer = new Konva.Layer();
    stage.add(layer);

    const initialRects = [];

    let selectedShape = null;

    function createRect(rectData) {
      const group = new Konva.Group({
        x: rectData.x,
        y: rectData.y,
        rotation: rectData.rotation,
        draggable: true,
      });

      const box = new Konva.Rect({
        width: rectData.width,
        height: rectData.height,
        fill: rectData.fill,
      });

      const text = new Konva.Text({
        text: rectData.id.toString(),
        fontSize: 20,
        fontFamily: 'Arial',
        fill: 'black',
        width: rectData.width,
        height: rectData.height,
        align: 'center',
        verticalAlign: 'middle',
      });

      const deleteButton = new Konva.Group({
        x: rectData.width - 20,
        y: -10,
        visible: false,
      });

      const deleteCircle = new Konva.Circle({
        radius: 10,
        fill: 'red',
      });

      const deleteX = new Konva.Text({
        text: 'X',
        fontSize: 15,
        fontFamily: 'Arial',
        fill: 'white',
        align: 'center',
        verticalAlign: 'middle',
        x: -5,
        y: -7,
      });

      deleteButton.add(deleteCircle);
      deleteButton.add(deleteX);

      group.add(box);
      group.add(text);
      group.add(deleteButton);

      group.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
      });

      group.on('mouseout', function () {
        document.body.style.cursor = 'default';
      });

      group.on('dragend transform', function () {
        logRectPositions();
      });

      group.on('click tap', function (e) {
        e.cancelBubble = true;
        if (selectedShape) {
          removeTransformer(selectedShape);
          selectedShape = null;
        }
        deleteButton.visible(!deleteButton.visible());
        setselectedShape(!selectedShape);
        setCodeRef(rectData.codeRef);
        setType(rectData.type);
        setEtat(rectData.etat);
        if (!deleteButton.visible()) {
          addTransformer(group);
          selectedShape = group;
          setselectedShape(true);
        }
        layer.draw();
        logRectPositions();
      });

      stage.on('click tap', function () {
        deleteButton.visible(false);
        setselectedShape(false);
        if (selectedShape) {
          removeTransformer(selectedShape);
          selectedShape = null;
          layer.draw();
        }
        logRectPositions();
      });

      deleteButton.on('click tap', function (e) {
        e.cancelBubble = true;
        group.destroy();
        layer.draw();
        logRectPositions();
      });

      group.on('transform', function () {
        const newWidth = box.width() * group.scaleX();
        const newHeight = box.height() * group.scaleY();
        box.setAttrs({
          width: newWidth,
          height: newHeight,
        });
        text.setAttrs({
          width: newWidth,
          height: newHeight,
          fontSize: (newWidth + newHeight) / 7.5
        });
        deleteButton.setAttrs({
          x: newWidth - 20,
        });
        group.setAttrs({
          scaleX: 1,
          scaleY: 1,
        });
      });

      group.id('rect-' + rectData.id);

      layer.add(group);
    }

    function addTransformer(group) {
      const transformer = new Konva.Transformer({
        nodes: [group],
        keepRatio: false,
        boundBoxFunc: function (oldBox, newBox) {
          newBox.width = Math.max(30, newBox.width);
          newBox.height = Math.max(30, newBox.height);
          return newBox;
        },
      });
      layer.add(transformer);
    }

    function removeTransformer(group) {
      const transformer = group.getParent().find('Transformer')[0];
      if (transformer) {
        transformer.destroy();
        setselectedShape(false);
      }
    }

    initialRects.forEach(createRect);
    layer.draw();

    function addRectangle(data) {
      setRectCount((prevCount) => {
        const newRect = {
          id: data.id,
          x: Math.random() * (stage.width() - 100),
          y: Math.random() * (stage.height() - 50),
          width: 100,
          height: 50,
          rotation: 0,
          fill: data.type === "post" ? "#AFE1AF" : "#FFBF00",
          type: data.type,
          etat: data.etat,
          codeRef: data.codeRef
        };
        createRect(newRect);
        layer.draw();
        logRectPositions();
        return prevCount + 1;
      });
    }

    function logRectPositions() {
      console.log('Current rectangle positions:');
      stage.find('Group').forEach(function (group) {
        if (group.getParent() === layer) {
          const id = group.id().split('-')[1];
          const position = group.position();
          const rotation = group.rotation();
          const box = group.findOne('Rect');
          const width = box.width();
          const height = box.height();
          console.log(group);
        }
      });
    }

    window.addRectangle = addRectangle;

    logRectPositions();

    const img = new Konva.Image({
      x: 0,
      y: 0,
      width: containerWidth,
      height: containerHeight,
    });
    layer.add(img);
    setBackgroundImage(img);

    stage.on('wheel', (e) => {
      e.evt.preventDefault();
      const scaleBy = 1.1;
      const oldScale = stage.scaleX();
    
      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      };
    
      let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    
      if (newScale < 1) {
        newScale = 1;
      }
    
      const newPos = {
        x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
      };
    
      if (e.evt.deltaY < 0 && newScale === 1) {
        newPos.x = 0;
        newPos.y = 0;
      }
    
      stage.scale({ x: newScale, y: newScale });
      stage.position(newPos);
      stage.batchDraw();
    });

  }, []);

  const handlePostSelection = (number) => {
    const postData = postsData.find(post => post.id === number);
    const data = {
      id: postData.id,
      type: postData.type,
      etat: postData.etat,
      codeRef: postData.codeRef
    };
    window.addRectangle(data);
    handleClose();
  };

  const handlePiegeSelection = (number) => {
    const piegeData = piegesData.find(piege => piege.id === number);
    const data = {
      id: piegeData.id,
      type: piegeData.type,
      etat: piegeData.etat,
      codeRef: piegeData.codeRef
    };
    window.addRectangle(data);
    handleCloseP();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new window.Image();
      img.onload = () => {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        
        setBackgroundImage((prevImg) => {
          if (prevImg) {
            prevImg.image(img);
            prevImg.width(containerWidth);
            prevImg.height(containerHeight);
          }
          return prevImg;
        });

        setInitialImageState({
          src: event.target.result,
          width: containerWidth,
          height: containerHeight,
          x: 0,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          rotation: 0,
        });

        console.log('Image Size:', containerWidth, 'x', containerHeight);
        console.log('Image Position:', 'x:', 0, 'y:', 0);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };
  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex', height: '500px'}}>
        <Box
          id="container"
          ref={containerRef}
          component="div"
          sx={{ 
            flexGrow: 1, 
            p: 0, 
            width: '80%', 
            height: '452px', 
            backgroundColor: '#8B4513', 
            overflowX: 'hidden',
            overflowY: 'hidden'
          }}
        >
        </Box>
        <Box
          sx={{
            width: "20%",
            height:"452px",
            paddingTop:"25px",
            backgroundColor: '#e8e8e8',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            rowGap:"80px"
          }}
        >
          <Box>
          <Button id="addRectButton" variant="contained" 
          sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"130px"
              }} onClick={handleOpen}>
            Ajouter Post
          </Button>
          <Button variant="contained" 
          sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"130px"
              }}
              onClick={handleOpenP}
              >
            Ajouter Piége
          </Button>
          <Button
        component="label"
        variant="contained"
        sx={{
          mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
          '&:hover': { bgcolor: '#a9281e' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width:"130px"
        }}
        
      >
        Ajouter Plan
        <input
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          hidden
        />
      </Button>
      </Box>
      {selectedShape ? 
  <Box sx={{display:"flex", alignItems:"start", flexDirection:"column", width:"100%", paddingLeft:"15px"}}>
    <Typography>CodeRef : {CodeRef} </Typography>
    <Typography>Type : {Type} </Typography>
    <Typography>Etat : {Etat} </Typography>
  </Box>
  : null
}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Select a Post
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 , display:"flex" , alignItems:"center" , flexDirection:"column" }}>
            {[1, 2, 3].map((number) => (
              <Button key={number} className='Posts' variant="contained"
              sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"130px"
              }} onClick={() => handlePostSelection(number)}>
                Post {number}
              </Button>
            ))}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openP}
        onClose={handleCloseP}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Select a Piége
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 , display:"flex" , alignItems:"center" , flexDirection:"column" }}>
            {[1, 2, 3].map((number) => (
              <Button key={number} className='Posts' variant="contained"
              sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"130px"
              }} onClick={() => handlePiegeSelection(number)}>
                Piége {number}
              </Button>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Plan;