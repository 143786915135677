import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Box,
  Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Navbar from './Navbar.js';

const initialData = [
  {
    category: 'Eaux et rejet d’eau usée',
    questions: [
      { question: 'Les regards sont-ils bien entretenus ?', yes: true, no: false, remark: '' },
      { question: 'Les canalisations sont-elles bien entretenues et étanches ?', yes: false, no: true, remark: '' },
      { question: 'Le local contient des tues-mouches à l’entrée ?', yes: true, no: false, remark: '' },
    ]
  },
  {
    category: 'Gestion des déchets',
    questions: [
      { question: 'La zone de déchet est-elle propre ?', yes: false, no: true, remark: '' },
      { question: 'Les poubelles sont-elles étanches ?', yes: false, no: true, remark: '' },
      { question: 'Les appâts au niveau de la zone des déchets sont accessibles ?', yes: true, no: false, remark: '' },
    ]
  },
  {
    category: 'Matière première',
    questions: [
      { question: 'Local de stockage est-il fermé ?', yes: false, no: false, remark: '' },
      { question: 'La distanciation palette-palette et palette mur est-elle respectée ?', yes: false, no: false, remark: '' },
      { question: 'Est-ce que les contenants et la zone de stockage sont propres ?', yes: false, no: false, remark: '' },
      { question: 'Les conditions de stockage sont-elles adéquates ?', yes: false, no: false, remark: '' },
      { question: 'Existe-t-il un encombrement ?', yes: false, no: false, remark: '' },
    ]
  },
];

const inputStyles = {
  backgroundColor: '#f2f3f3',
  mb: 2,
  height: '45px',
  borderRadius: '5px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#C0C0C0',
    },
    '&:hover fieldset': {
      borderColor: '#930e0e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#930e0e',
    },
  },
  '& label.Mui-focused': {
    color: '#930e0e',
    fontWeight: 'bold',
  },
};

const ButtonStyles = {
  mb: 2,
  bgcolor: '#930E0E',
  borderRadius: '5px',
  fontFamily: 'Roboto',
  '&:hover': { bgcolor: '#a9281e' },
  color: 'white',
  textTransform: 'none',
  width: '15%',
};

const AuditTable = () => {
  const [data, setData] = useState(initialData);

  const handleIconClick = (sectionIndex, questionIndex, field) => {
    const newData = [...data];
    if (field === 'yes') {
      newData[sectionIndex].questions[questionIndex].yes = !newData[sectionIndex].questions[questionIndex].yes;
      if (newData[sectionIndex].questions[questionIndex].yes) {
        newData[sectionIndex].questions[questionIndex].no = false;
      }
    } else if (field === 'no') {
      newData[sectionIndex].questions[questionIndex].no = !newData[sectionIndex].questions[questionIndex].no;
      if (newData[sectionIndex].questions[questionIndex].no) {
        newData[sectionIndex].questions[questionIndex].yes = false;
      }
    }
    setData(newData);
  };

  const handleRemarkChange = (sectionIndex, questionIndex, value) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].remark = value;
    setData(newData);
  };

  const handleConfirm = () => {
    console.log('Confirmed Data:', data);
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2, ml: 2, width: '40%' }}
        >
          Check List de contrôle
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Appréciations</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Remarque</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  <TableRow>
                    <TableCell colSpan={3} style={{ fontWeight: 'bold', borderBottom: '1px solid #E0E0E0' }}>
                      {section.category}
                    </TableCell>
                  </TableRow>
                  {section.questions.map((item, questionIndex) => (
                    <TableRow key={questionIndex}>
                      <TableCell style={{ width: '40%', borderBottom: '1px solid #E0E0E0' }}>{item.question}</TableCell>
                      <TableCell align="center" style={{ borderBottom: '1px solid #E0E0E0' }}>
                        <IconButton
                          color={item.yes ? 'success' : 'default'}
                          onClick={() => handleIconClick(sectionIndex, questionIndex, 'yes')}
                        >
                          <CheckCircleOutlineIcon style={{ color: item.yes ? 'green' : undefined }} />
                        </IconButton>
                        <IconButton
                          color={item.no ? 'error' : 'default'}
                          onClick={() => handleIconClick(sectionIndex, questionIndex, 'no')}
                        >
                          <CancelOutlinedIcon style={{ color: item.no ? 'red' : undefined }} />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #E0E0E0' }}>
                        <TextField
                          value={item.remark}
                          onChange={(e) => handleRemarkChange(sectionIndex, questionIndex, e.target.value)}
                          fullWidth
                          sx={inputStyles}
                          disabled={item.yes}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" m={2}>
          <Button variant="contained" color="primary" onClick={handleConfirm} sx={ButtonStyles}>
            Confirmer
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AuditTable;
