import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./Components/Login";
import UserSetting from "./Components/UserSettings.js";
import Dashboard from "./Components/Dashboard.js";
import AddClient from "./Components/AddClient.js";
import ClientTable from "./Components/ClientTable.js";
import Navbar from "./Components/Navbar.js";
import ProduitTable from "./Components/ProduitTable.js";
import AddProduct from "./Components/AddProduit.js";
import UserTable from "./Components/UserTable.js";
import AddUser from "./Components/AddUser.js";
import AddProductProd from "./Components/AddProductDoc.js";
import AddClientDocument from "./Components/AddClientDoc.js";
import ProdDocumentTable from "./Components/ProductDocTable.js";
import DocumentClientTable from "./Components/ClientDocTable.js";
import CalendarPage from "./Components/CalendarPage.js";
import AuditTable from "./Components/CheckListControl.js";
import DashboardClient from "./Components/DashboardClient.js";
import DashboardTech from "./Components/DashboardTechne.js";
import DataTable from "./Components/PostePiegesControle.js";
import UpdateUser from "./Components/UpdateUser.js";
import UpdateClient from "./Components/UpdateClient.js";
import UpdateProduct from "./Components/UpdateProduct.js";
import StockTable from "./Components/StockTable.js";
import AddStock from "./Components/AddStock.js";
import UpdateStock from "./Components/UpdateStock.js";
import MatierActivesTable from "./Components/MatiersActivesTable.js";
import AddMatierActive from "./Components/AddMatiersActive.js";
import UpdateMatiereActive from "./Components/UpdateMatiereActive.js";
import InterventionTable from "./Components/InterventionTable.js";
import AddInterventions from "./Components/AddInterventions.js";
import UpdateIntervention from "./Components/UpdateInterventions.js";
import Plan from "./Components/Plan.js";




function App() {
  return (
  
      
      <Routes>
        <Route path="/plan" element={<Plan/>}/>
         <Route path="/AuditTable" element={<AuditTable/>} />
         <Route path="/UdpateUser/:id" element={<UpdateUser/>} />
         <Route path="/UserTable" element={<UserTable/>} />
         <Route path="/DataTable" element={<DataTable/>} />
         <Route path="/DashboardTech" element={<DashboardTech/>} />
         <Route path="/DashboardClient" element={<DashboardClient/>} />
         <Route path="/CalendarPage" element={<CalendarPage/>} />
         <Route path="/ProdDocumentTable" element={<ProdDocumentTable/>} />
         <Route path="/DocumentClientTable" element={<DocumentClientTable/>} />
         <Route path="/AddClientDocument" element={<AddClientDocument/>} />
         <Route path="/AddProductProd" element={<AddProductProd/>} />
         <Route path="/AddUser" element={<AddUser/>} />
         <Route path="/ClientTable" element={<ClientTable/>} />
         <Route path="/UpdateClient/:id" element={<UpdateClient/>} />
         <Route path="/AddProduct" element={<AddProduct/>} />
         <Route path="/ProduitTable" element={<ProduitTable/>} />
         <Route path="/UdpateProduits/:id" element={<UpdateProduct/>} />
         <Route path="/Navbar" element={<Navbar/>} />
         <Route path="/AddClient" element={<AddClient/>} />
         <Route path="/" element={<Login/>} />
         <Route path="/Dashboard" element={<Dashboard/>} />
         <Route path="/UserSetting" element={<UserSetting/>} />
         <Route path="/StockTable" element={<StockTable/>}/>
         <Route path="/AddStock" element={<AddStock/>}/>
         <Route path="/UpdateStock/:id" element={<UpdateStock/>}/>
         <Route path="/MatiereActiveTable" element={<MatierActivesTable/>}/>
         <Route path="/AddMatiereActive" element={<AddMatierActive/>}/>
         <Route path="/UpdateMatiereActive/:id" element={<UpdateMatiereActive/>}/>
         <Route path="/interventionsTable" element={<InterventionTable/>}/>
         <Route path="/AddInterventions" element={<AddInterventions/>}/>
         <Route path="/UpdateIntervention/:id" element={<UpdateIntervention/>}/>
      </Routes>

  );
}

export default App;
