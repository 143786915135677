import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Button, Select, MenuItem, FormControl, Alert, Snackbar  } from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AddUser() {
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState('');
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [open ,setOpen] = useState(false)
    const [opener ,setOpener] = useState(false)
    const [message , setmessage ] = useState("")
    const navigate = useNavigate()
    useEffect(()=>{
        getroles()
    },[])
    const getroles = ()=>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/roles")
        .then((res)=>{
            setRoles(res.data)
        })
    }
    const handlsubmit=()=>{
        const data = {
            email:email,
            Password:password,
            Nom:nom,
            Prenom:prenom,
            Login:login,
            role_id:role
        }
        axios.post("https://serv.pestima.yuliagroupe.com/api/users",data)
        .then((res)=>{
            if(res.data.error){
                setmessage(res.data.message)
                setOpener(true)
            }else{
                setmessage(res.data.message)
                setOpen(true)
                setTimeout(() => {
                    navigate("/UserTable")
                }, 3000);
            }
        })
    }

    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };
    const handleCloseer = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpener(false);
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    
    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Ajouter Utilisateur</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Nom de Utilisateur</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nom}
                                    onChange={(e) => { setNom(e.target.value) }}
                                    id="Nom"
                                    name="Nom"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Prenom d'Utilisateur</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={prenom}
                                    onChange={(e) => { setPrenom(e.target.value) }}
                                    id="prenom"
                                    name="prenom"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold', }}>Role</Typography>
                                <FormControl
                                    required
                                    fullWidth
                                    variant="outlined"
                                    sx={inputStyles}
                                >
                                    <Select
                                        labelId="type-label"
                                        id="role"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                    >
                                        {roles.map((role)=>(
                                            <MenuItem value={role.id_role}>{role.role}</MenuItem>
                                        ))}
                                        
                                        
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Login</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={login}
                                    onChange={(e) => { setLogin(e.target.value) }}
                                    id="login"
                                    name="login"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Password</Typography>
                                <TextField
                                    type='password'
                                    required
                                    fullWidth
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    id="password"
                                    name="password"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}
                            onClick={handlsubmit}
                            >
                                Ajouter
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default AddUser;
