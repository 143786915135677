import React, { useEffect, useState,useRef } from 'react';
import Navbar from './Navbar';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import divider from './img/divider.png'
import cacher from "./img/cacher.png"

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography,
    Autocomplete,
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';

moment.locale('fr');
const localizer = momentLocalizer(moment);

const inputStyles = {
    backgroundColor: '#f2f3f3',
    mb: 2,
    height: '45px',
    borderRadius: '5px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#C0C0C0',
            height: "90%",
        },
        '&:hover fieldset': {
            borderColor: '#930e0e',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#930e0e',
        },
    },
    '& label.Mui-focused': {
        color: '#930e0e',
        fontWeight: 'bold',
    },
};

const ButtonStyles = {
    mb: 2,
    bgcolor: '#930E0E',
    borderRadius: '5px',
    fontFamily: 'Roboto',
    '&:hover': { bgcolor: '#a9281e' },
    color: 'white',
    textTransform: 'none',
    width: '15%',
};

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

function CalendarPage() {
    const [date, setDate] = useState(new Date());
    const certificateRef = useRef();
    const [view, setView] = useState('month');
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [eventDetails, setEventDetails] = useState({ deratisationProducts: [], desinsectisationProducts: [], dereptilisationProducts: [] });
    const [openDialog, setOpenDialog] = useState(false);
    const [intervention, setIntervention] = useState("");
    const [interventions, setInterventions] = useState([]);
    const [debut, setDebut] = useState("");
    const [fin, setFin] = useState("");
    const [deratisationProducts, setDeratisationProducts] = useState([]);
    const [desinsectisationProducts, setDesinsectisationProducts] = useState([]);
    const [dereptilisationProducts, setDereptilisationProducts] = useState([]);
    const [initialDeratisationProducts, setInitialDeratisationProducts] = useState([]);
    const [initialDesinsectisationProducts, setInitialDesinsectisationProducts] = useState([]);
    const [initialDereptilisationProducts, setInitialDereptilisationProducts] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const getCalenderdate = () => {
        axios.get('https://serv.pestima.yuliagroupe.com/api/passages')
            .then((res) => {
                const transformedData = res.data.map(passage => ({
                    id: passage.id_passage,
                    title: `${passage.intervention.client.Nom} ${passage.intervention.client.Prenom}`,
                    description: '',
                    start: new Date(passage.date_debut),
                    end: new Date(passage.date_fin),
                    intervention: passage.intervention,
                    deratisationProducts: passage.passage_stocks.filter(stock => stock.type_traitement === 'deratisation').map(stock => ({ id: stock.id_stock, nom: stock.stock.produit.nom_produits ,matiereActive: stock.stock.produit.matieres_actives })),
                    desinsectisationProducts: passage.passage_stocks.filter(stock => stock.type_traitement === 'desinsectisation').map(stock => ({ id: stock.id_stock, nom: stock.stock.produit.nom_produits ,matiereActive: stock.stock.produit.matieres_actives})),
                    dereptilisationProducts: passage.passage_stocks.filter(stock => stock.type_traitement === 'dereptilisation').map(stock => ({ id: stock.id_stock, nom: stock.stock.produit.nom_produits ,matiereActive: stock.stock.produit.matieres_actives }))
                }));
                setData(transformedData);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getInterventions = () => {
        axios.get('https://serv.pestima.yuliagroupe.com/api/interventions')
            .then((res) => {
                setInterventions(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getProducts = () => {
        axios.get('https://serv.pestima.yuliagroupe.com/api/stocks')
            .then((res) => {
                const products = res.data.map(stock => ({
                    id: stock.id_stock,
                    nom: stock.produit.nom_produits,
                    matiereActive:stock.produit.matieres_actives
                }));
                setDeratisationProducts(products);
                setDesinsectisationProducts(products);
                setDereptilisationProducts(products);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        getCalenderdate();
        getInterventions();
        getProducts();
    }, []);

    const handleNavigate = (newDate) => {
        setDate(newDate);
    };

    const handleViewChange = (newView) => {
        setView(newView);
    };

    const handleSelectSlot = ({ start, end }) => {
        setSelectedEvent(null);
        setDebut(moment(start).format('YYYY-MM-DDTHH:mm'));
        setFin(moment(end).format('YYYY-MM-DDTHH:mm'));
        setIntervention("");
        setEventDetails({ deratisationProducts: [], desinsectisationProducts: [], dereptilisationProducts: [] });
        setInitialDeratisationProducts([]);
        setInitialDesinsectisationProducts([]);
        setInitialDereptilisationProducts([]);
        setOpenDialog(true);
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setDebut(moment(event.start).format('YYYY-MM-DDTHH:mm'));
        setFin(moment(event.end).format('YYYY-MM-DDTHH:mm'));
        setIntervention(event.intervention.id_intervention);
        setEventDetails({
            deratisationProducts: event.deratisationProducts,
            desinsectisationProducts: event.desinsectisationProducts,
            dereptilisationProducts: event.dereptilisationProducts
        });
        setInitialDeratisationProducts(event.deratisationProducts);
        setInitialDesinsectisationProducts(event.desinsectisationProducts);
        setInitialDereptilisationProducts(event.dereptilisationProducts);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDebut("");
        setFin("");
        setIntervention("");
        setEventDetails({ deratisationProducts: [], desinsectisationProducts: [], dereptilisationProducts: [] });
    };

    const handlePlanningChange = (event, value, field) => {
        setEventDetails(prevDetails => ({
            ...prevDetails,
            [field]: value
        }));
    };

    const handleSaveEvent = () => {
        const newEvent = {
            date_debut: debut,
            date_fin: fin,
            intervention_id: intervention,
        };

        if (selectedEvent) {
            axios.put(`https://serv.pestima.yuliagroupe.com/api/passages/${selectedEvent.id}`, newEvent)
                .then(() => {
                    handleUpdatePassageStocks(selectedEvent.id);
                    setOpenDialog(false);
                })
                .catch(error => {
                    console.error("There was an error updating the event!", error);
                });
        } else {
            axios.post('https://serv.pestima.yuliagroupe.com/api/passages', newEvent)
                .then((res) => {
                    const passageId = res.data.id_passage;
                    handleAddPassageStocks(passageId);
                    setOpenDialog(false);
                })
                .catch(error => {
                    console.error("There was an error saving the event!", error);
                });
        }
    };

    const handleAddPassageStocks = (passageId) => {
        const stockData = [
            ...eventDetails.deratisationProducts.map(productId => ({ id_passage: passageId, id_stock: productId.id, type_traitement: 'deratisation' })),
            ...eventDetails.desinsectisationProducts.map(productId => ({ id_passage: passageId, id_stock: productId.id, type_traitement: 'desinsectisation' })),
            ...eventDetails.dereptilisationProducts.map(productId => ({ id_passage: passageId, id_stock: productId.id, type_traitement: 'dereptilisation' })),
        ];

        stockData.forEach(stockItem => {
            axios.post('https://serv.pestima.yuliagroupe.com/api/passage_stock', stockItem)
                .then(() => {
                    getCalenderdate();
                })
                .catch(error => {
                    console.error("There was an error adding the stock!", error);
                });
        });
    };

    const handleUpdatePassageStocks = (passageId) => {
        const compareAndUpdate = (initial, current, type_traitement) => {
            const toAdd = current.filter(item => !initial.some(i => i.id === item.id));
            const toRemove = initial.filter(item => !current.some(i => i.id === item.id));

            toAdd.forEach(stockItem => {
                axios.post('https://serv.pestima.yuliagroupe.com/api/passage_stock', { id_passage: passageId, id_stock: stockItem.id, type_traitement })
                    .then(() => {
                        getCalenderdate();
                    })
                    .catch(error => {
                        console.error("There was an error adding the stock!", error);
                    });
            });

            toRemove.forEach(stockItem => {
                axios.delete(`https://serv.pestima.yuliagroupe.com/api/passage_stock/${passageId}/${stockItem.id}`)
                    .then(() => {
                        getCalenderdate();
                    })
                    .catch(error => {
                        console.error("There was an error removing the stock!", error);
                    });
            });
        };

        compareAndUpdate(initialDeratisationProducts, eventDetails.deratisationProducts, 'deratisation');
        compareAndUpdate(initialDesinsectisationProducts, eventDetails.desinsectisationProducts, 'desinsectisation');
        compareAndUpdate(initialDereptilisationProducts, eventDetails.dereptilisationProducts, 'dereptilisation');
    };

    const CustomEvent = ({ event }) => (
        <span>{event.title}</span>
    );

    const handleDeleteEvent = () => {
        if (selectedEvent) {
            axios.delete(`https://serv.pestima.yuliagroupe.com/api/passages/${selectedEvent.id}`)
                .then(() => {
                    setOpenDialog(false);
                    getCalenderdate();
                })
                .catch(error => {
                    console.error("There was an error deleting the event!", error);
                });
        }
    };


    //pdf

    const downloadPdf = () => {
        const input = certificateRef.current;
        html2canvas(input, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [210, 297], // A4 size in landscape
          });
          pdf.addImage(imgData, 'PNG', 0, 0, 297, 210);
          pdf.save('certificate.pdf');
        });
      };
    
      const today = new Date();
      const formattedDate = formatDate(today);


    return (
        <>
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h5" gutterBottom component="div" sx={{ fontFamily: 'Roboto, sans-serif', width: '100%', mb: 2, fontWeight: 'bold' }}>
                            Calendrier des Passages
                        </Typography>
                        <Box sx={{ width: '100%', mx: "auto" }}>
                            <Toolbar>
                                <Button startIcon={<ArrowBackIos sx={{ color: '#930E0E' }} />} onClick={() => handleNavigate(moment(date).add(-1, view).toDate())} />
                                <Button endIcon={<ArrowForwardIos sx={{ color: '#930E0E' }} />} onClick={() => handleNavigate(moment(date).add(1, view).toDate())} />
                                <Typography variant="h6" sx={{ ml: 2, mr: 'auto', color: '#930E0E' }}>{moment(date).format('MMMM YYYY')}</Typography>
                                <Button onClick={() => handleViewChange('day')}>Jour</Button>
                                <Button onClick={() => handleViewChange('week')}>Semaine</Button>
                                <Button onClick={() => handleViewChange('month')}>Mois</Button>
                            </Toolbar>
                            <Calendar
                                localizer={localizer}
                                events={data}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                                date={date}
                                onNavigate={handleNavigate}
                                onView={handleViewChange}
                                view={view}
                                selectable
                                onSelectSlot={handleSelectSlot}
                                onSelectEvent={handleSelectEvent}
                                components={{ event: CustomEvent }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Planifier un Passage</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={inputStyles}
                        label="Date de début"
                        type="datetime-local"
                        value={debut}
                        onChange={(e) => setDebut(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        sx={inputStyles}
                        label="Date de fin"
                        type="datetime-local"
                        value={fin}
                        onChange={(e) => setFin(e.target.value)}
                        fullWidth
                    />
                    <FormControl sx={{ mb: 2, width: '100%' }}>
                        <InputLabel>Intervention</InputLabel>
                        <Select
                            value={intervention}
                            onChange={(e) => setIntervention(e.target.value)}
                            fullWidth
                        >
                            {interventions.map(interv => (
                                <MenuItem key={interv.id_intervention} value={interv.id_intervention}>
                                    {`${interv.client.Nom} ${interv.client.Prenom}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Autocomplete
                        multiple
                        options={deratisationProducts}
                        getOptionLabel={(option) => option.nom}
                        value={eventDetails.deratisationProducts}
                        onChange={(event, value) => handlePlanningChange(event, value, 'deratisationProducts')}
                        renderInput={(params) => <TextField {...params} label="Produits de dératisation" />}
                        sx={inputStyles}
                    />
                    <Autocomplete
                        multiple
                        options={desinsectisationProducts}
                        getOptionLabel={(option) => option.nom}
                        value={eventDetails.desinsectisationProducts}
                        onChange={(event, value) => handlePlanningChange(event, value, 'desinsectisationProducts')}
                        renderInput={(params) => <TextField {...params} label="Produits de désinsectisation" />}
                        sx={inputStyles}
                    />
                    <Autocomplete
                        multiple
                        options={dereptilisationProducts}
                        getOptionLabel={(option) => option.nom}
                        value={eventDetails.dereptilisationProducts}
                        onChange={(event, value) => handlePlanningChange(event, value, 'dereptilisationProducts')}
                        renderInput={(params) => <TextField {...params} label="Produits de déréptilisation" />}
                        sx={inputStyles}
                    />
                </DialogContent>
                <DialogActions>
                {selectedEvent && (
                    <>
                       <Button onClick={downloadPdf} sx={{...ButtonStyles , width:"190px"}}>
                       Télécharger l'attestation
                   </Button>
                        <Button onClick={handleDeleteEvent} sx={{ ...ButtonStyles, bgcolor: '#d32f2f', '&:hover': { bgcolor: '#c62828' } }}>
                            Supprimer
                        </Button>
                        </>
                    )}
                <Button onClick={handleDialogClose} sx={ButtonStyles}>
                        Annuler
                    </Button>

                                        <Button onClick={handleSaveEvent} sx={ButtonStyles}>
                        {selectedEvent ? 'Modifier' : 'Ajouter'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

        {/* pdf */}
        <div>
<div
  ref={certificateRef}
  id='containerpdf'
  style={{
    padding: '20px',
    width: '297mm',
    height: '210mm',
    border: '1px solid #ddd',
    marginBottom: '20px',
    boxSizing: 'border-box',
    backgroundImage: 'url(./bg.png)',
    backgroundSize: 'contain',
    position: 'absolute',
    left: '-9999px',
  }}
>
  <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '25px' }}>
    <div style={{ marginLeft: '50px', display: 'flex' }}>
      <img src='/logo.png' alt='Logo' style={{ height: '60px' }} />
    </div>
    <h1 style={{fontFamily:"Yeseva One" , fontSize:42 , marginBottom:"0px"}}>ATTESTATION</h1>
    <p style={{fontFamily:"Open Sans"}}>
      Nous soussignés, société <b>PESTIMA</b> certifions que<br />
      Le traitement de dératisation, désinsectisation et déreptilisation a été effectué dans l'ensemble des locaux de :
    </p>
    <h2 style={{fontFamily:"Yeseva One" , color:"#C00000" , fontSize:26 , margin:"5px 0px"}}>{selectedEvent ? selectedEvent.title : null}</h2>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ display: 'flex', justifyContent: 'space-around', width: '90%' ,columnGap:"200px" }}>
      <div style={{ flex: '1', textAlign: 'center' }}>
        <h3 style={{marginRight:"70px" , marginBottom:"0px", marginTop:"10px",fontFamily:"Open Sans"}}>DERATISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' }}>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454" , fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedEvent ? selectedEvent.deratisationProducts.map((product)=>(
                <>
                <p style={{margin:"0px"}}>{product.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{product.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
          
          
        </div>
      </div>
      <div style={{ flex: '1', textAlign: 'center' }}>
        <h3 style={{marginRight:"50px" , marginBottom:"0px",marginTop:"10px" ,fontFamily:"Open Sans"}}>DESINSECTISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' }}>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedEvent ? selectedEvent.desinsectisationProducts.map((product)=>(
                <>
                <p style={{margin:"0px"}}>{product.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{product.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
        </div>
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
      <div style={{ textAlign: 'center' }}>
        <h3 style={{marginRight:"20px" , marginBottom:"0px" , fontFamily:"Open Sans" , marginTop:2}}>DERAPTILISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' , columnGap:"80px" }}>
          <p style={{margin:"0px" , marginBottom:"2px" , fontFamily:"Carlito", color:"#545454", fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedEvent ? selectedEvent.dereptilisationProducts.map((product)=>(
                <>
                <p style={{margin:"0px"}}>{product.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{product.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
        </div>
      </div>
    </div>
  </div>
  <div style={{ textAlign: 'center', marginTop: '10px' }}>
    <p style={{margin:"0px" , fontWeight:"700"}}>Ce certificat est délivré pour servir et valoir ce que de droit.</p>
    <img src={divider} width="60px"/>
  </div>
  <div style={{display:"flex" , flexDirection:"column" , alignItems:"center" , position:"absolute" , right:"100px" , top:"600px"}}>
  <p style={{margin: "0px",fontFamily:"Open Sans" , fontSize:14}}><b style={{color:"#C00000"}}>FAIT À :</b> {`CASABLANCA`} <b style={{color:"#C00000"}}>LE : </b>{formattedDate}</p>
  <p style={{margin:"0px",fontFamily:"Open Sans" , color:"#808080",fontSize:14 , fontWeight:700}}>DIRECTION </p>
  <img src={cacher} style={{marginTop:"5px"}}/>
  </div>
</div>


    </div>

        </>
    );
}

export default CalendarPage;
