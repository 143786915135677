import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Autocomplete, Chip, Snackbar, Alert } from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

function UpdateProduct() {
    const [nomProduit, setNomProduit] = useState('');
    const [matieresActives, setMatieresActives] = useState([]);
    const [getmatieresActives, setgetMatieresActives] = useState([]);
    const [getmatieresActivesPro, setgetMatieresActivesPro] = useState([]);
    const [open ,setOpen] = useState(false)
    const [opener ,setOpener] = useState(false)
    const [message , setmessage ] = useState("")
    const fontFamily = 'Roboto, sans-serif';
    const navigate = useNavigate()
    const {id}=useParams()
    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
        '& .css-1tw9w9k-MuiAutocomplete-root':{
            position : 'relative',
            top : '-10px'
        }
    };


useEffect(()=>{
    axios.get("https://serv.pestima.yuliagroupe.com/api/matieres-actives")
    .then((res)=>{
        setgetMatieresActives(res.data)
    })
    axios.get("https://serv.pestima.yuliagroupe.com/api/produits/"+id)
    .then((res)=>{
        setNomProduit(res.data.nom_produits)
        const ids = res.data.matieres_actives.map(item => item.id_matieres_actives);
        setMatieresActives(ids);
        setgetMatieresActivesPro(res.data.produits_matiere_active)
    })
},[])

const handleDeleteMatieresActives = async (matieresToDelete) => {
    for (let i = 0; i < matieresToDelete.length; i++) {
        try {
            await axios.delete(`https://serv.pestima.yuliagroupe.com/api/produits-matieres-actives/${matieresToDelete[i].id_produis_matiere_active}`);
        } catch (error) {
            console.error("Error deleting matiere active", error);
        }
    }
};

const handleAddMatieresActives = async (matieresToAdd, produitId) => {
    for (let i = 0; i < matieresToAdd.length; i++) {
        const matiere = matieresToAdd[i];
        try {
            await axios.post(`https://serv.pestima.yuliagroupe.com/api/produits-matieres-actives`, {
                produits_id: produitId,
                id_matieres_actives: matiere.id_matieres_actives
            });
        } catch (error) {
            console.error("Error adding matiere active", error);
        }
    }
};

const handleUpdateMatieresActives = async (matieresToUpdate, produitId) => {
    for (let i = 0; i < matieresToUpdate.length; i++) {
        const matiere = matieresToUpdate[i];
        try {
            await axios.put(`https://serv.pestima.yuliagroupe.com/api/produits-matieres-actives/${matiere.id_produis_matiere_active}`, {
                produits_id: produitId,
                id_matieres_actives: matiere.id_matieres_actives
            });
        } catch (error) {
            console.error("Error updating matiere active", error);
        }
    }
};

const handlesubmit = () => {
    const data = { nom_produits: nomProduit };

    axios.put(`https://serv.pestima.yuliagroupe.com/api/produits/${id}`, data)
        .then(async (res) => {
            if (!res.data.error) {
                const produitId = res.data.produit.id_produits;

                // Identify matieres_actives to delete
                const matieresToDelete = getmatieresActivesPro.filter(
                    matiere => !matieresActives.includes(matiere.id_matieres_actives)
                );

                // Identify matieres_actives to add
                const existingIds = getmatieresActivesPro.map(ma => ma.id_matieres_actives);
                const matieresToAdd = matieresActives.filter(id => !existingIds.includes(id))
                                                      .map(id => ({ id_matieres_actives: id }));

                // Identify matieres_actives to update
                const matieresToUpdate = getmatieresActivesPro.filter(
                    matiere => matieresActives.includes(matiere.id_matieres_actives)
                );

                // Handle deletions, additions, and updates
                await handleDeleteMatieresActives(matieresToDelete);
                await handleAddMatieresActives(matieresToAdd, produitId);
                await handleUpdateMatieresActives(matieresToUpdate, produitId);

                setmessage("Product updated successfully");
                setOpen(true);
                setTimeout(() => {
                    navigate("/ProduitTable");
                }, 3000);
            } else {
                setmessage(res.data.message);
                setOpener(true);
            }
        })
        .catch((error) => {
            console.error("Error updating product", error);
            setmessage("Error updating product");
            setOpener(true);
        });
};


const handleCloseer = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpener(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Modifier Produit</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Nom de Produit</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nomProduit}
                                    onChange={(e) => { setNomProduit(e.target.value) }}
                                    id="nomProduit"
                                    name="nomProduit"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Matières Actives</Typography>
                                <Autocomplete
    multiple
    options={getmatieresActives ? getmatieresActives
        .filter(ma => !matieresActives.includes(ma.id_matieres_actives))
        .map((ma) => ({ id_matieres_actives: ma.id_matieres_actives, Matieres_actives: ma.Matieres_actives })) : []
    }
    sx={{"& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input , .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input":{position:"relative" , top:"-10px"}}}
    getOptionLabel={(option) => 
        option.Matieres_actives}
    value={matieresActives.map(id_matieres_actives => getmatieresActives.find(ma => ma.id_matieres_actives === id_matieres_actives))}
    onChange={(event, newValue) => {
        setMatieresActives(newValue.map(option => option.id_matieres_actives));
    }}
    renderTags={(value, getTagProps) =>
        value.map((option, index) => (
            <Chip
                key={option ? option.id_matieres_actives:null}
                label={option ?option.Matieres_actives:null }
                {...getTagProps({ index })}
                sx={{height:"24px" , top:"-10.5px"}}
            />
        ))
    }
    renderInput={(params) => (
        <TextField
            {...params}
            variant="outlined"
            sx={inputStyles}
        />
    )}
/>


                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}
                            onClick={handlesubmit}
                            >
                                Modifier
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

        </Box>
    );
}

export default UpdateProduct;
